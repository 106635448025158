import React from 'react'
import PropTypes from 'prop-types'

import Facebook from './Facebook'
import Twitter from './Twitter'
import Instagram from './Instagram'
import Linkedin from './Linkedin'

/**
 * @typedef {Object} PropType
 * @property {string} className
 * @property {Object} style
 * @property {string} width
 * @property {string} height
 * @property {string} fill
 * @property {string} stroke - very few icons have a stroke. Check the icon to see if it's supported.
 */

/**
 * Props
 * @property {('instagram' | 'facebook' | 'linkedin' | 'twitter')} name - Name of the Icon.
 * @param {PropType} props
 * @returns
 */
const Icons = ({ name, ...props }) => {
  switch (name) {
    case 'twitter':
      return <Twitter {...props} />
    case 'linkedin':
      return <Linkedin {...props} />
    case 'instagram':
      return <Instagram {...props} />
    case 'facebook':
      return <Facebook {...props} />
    default:
      return null
  }
}

Icons.propTypes = {
  name: PropTypes.string.isRequired
}

export default Icons

import React, { useState } from 'react'
import axios from '../../utils/API'
import Icon from '../Icons'
import Input from '../Input'

import classes from './ContactForm.module.css'

const Footer = () => {
  const [values, setValues] = useState({
    contactName: '',
    contactEmail: '',
    contactMessage: ''
  })

  const [state, setState] = useState({
    isSending: false,
    mailSent: false,
    error: null
  })

  const handleSubmit = async e => {
    e.preventDefault()
    setState(prevState => ({
      ...prevState,
      isSending: true
    }))
    try {
      const res = await axios.post('/contact', values)
      console.log(res)
      if (res.status === 200) {
        setState(prevState => ({
          ...prevState,
          mailSent: true,
          isSending: false
        }))
      }
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        error: e.message,
        isSending: false
      }))
    }
  }

  const handleChange = e => {
    e.persist()
    setValues(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <div className={classes.sectionFive}>
      <div className={classes.contactMain}>
        <div className={classes.contactHeader}>
          <h2>Contact Us</h2>
          <p>Have any questions or need assistance? Drop us a line below!</p>
        </div>
        <div className={classes.contactBody}>
          <div className={classes.companyInfo}>
            <div className={classes.companyDetails}>
              <div className={classes.title}>Visit</div>
              <div className={classes.info}>
                1334 Brittmoore Road
                <br />
                Houston, TX 77045
              </div>
            </div>
            <div className={classes.companyDetails}>
              <div className={classes.title}>Email</div>
              <div className={classes.companyEmail}>
                <a href="mailto:hello@varsityhype.com?subject = ContactForm">
                  hello@varsityhype.com
                </a>
              </div>
            </div>
            <div className={classes.companyDetails}>
              <div className={classes.title}>Social</div>
              <div className={classes.info}>
                <a href="https://www.facebook.com/varsityhype">
                  <Icon
                    name="facebook"
                    width="24px"
                    height="24px"
                    fill="#fff"
                    className={classes.socialMediaIcon}
                  />
                </a>

                <a href="https://twitter.com/VarsityHype">
                  <Icon
                    name="twitter"
                    width="24px"
                    height="24px"
                    fill="#fff"
                    className={classes.socialMediaIcon}
                  />
                </a>
                <a href="https://www.linkedin.com/company/varsity-hype/">
                  <Icon
                    name="linkedin"
                    width="24px"
                    height="24px"
                    fill="#fff"
                    className={classes.socialMediaIcon}
                  />
                </a>
                <a href="https://www.instagram.com/varsityhype/">
                  <Icon
                    name="instagram"
                    width="24px"
                    height="24px"
                    fill="#fff"
                    className={classes.socialMediaIcon}
                  />
                </a>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit} className={classes.contactForm}>
            <div className={classes.nameEmail}>
              <Input
                type="text"
                name="contactName"
                value={values.contactName}
                placeholder="Your name"
                onChange={handleChange}
                className={`${classes.rightMargin} ${classes.bottomMargin}`}
              />
              <Input
                type="text"
                name="contactEmail"
                value={values.contactEmail}
                placeholder="Your email"
                onChange={handleChange}
                className={`${classes.leftMargin}`}
              />
            </div>
            <textarea
              name="contactMessage"
              value={values.contactMessage}
              className={classes.message}
              rows="7"
              placeholder="Message"
              onChange={handleChange}
            />
            <div className={classes.flexRow}>
              <button
                type="submit"
                className={classes.submit}
                disabled={state.isSending}
              >
                {state.isSending ? (
                  <div className={classes.spinner}>Submitting</div>
                ) : (
                  <>Submit</>
                )}
              </button>
              {state.mailSent && <p>Thank you for contacting us!</p>}
              {state.error && (
                <p className={classes.errorEmail}>{state.error}</p>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Footer

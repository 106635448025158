import React from "react"
import PropTypes from "prop-types"

function SolidColorLogo({ className, height, width, fill, style }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      className={className}
      style={style}
      height={height}
      width={width}
      // style="enable-background:new 0 0 500 500;"
      // xml:space="preserve"
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M222.67,496.26v-70.65h18.9V252.36h-18.9v-71.1h124.2v71.1h-18.9v43.2h61.65v-43.2h-19.35v-71.1h124.65v71.1h-18.45
			v173.25h18.45v70.65H370.27v-70.65h19.35v-52.65h-61.65v52.65h18.9v70.65H222.67z M339.22,488.61v-55.8h-18.9v-67.5h76.95v67.5
			h-18.9v55.8h109.35v-55.8h-19.35v-188.1h19.35v-55.8H378.37v55.8h18.9v58.5h-76.95v-58.5h18.9v-55.8h-108.9v55.8h18.45v188.1
			h-18.45v55.8H339.22z M240.22,478.71v-36h18.45v-207.9h-18.45v-36h89.55v36h-18.9v77.85h95.85v-77.85h-18.9v-36h90v36h-18.9v207.9
			h18.9v36h-90v-36h18.9v-87.3h-95.85v87.3h18.9v36H240.22z"
          />
        </g>
        <g>
          <polygon
            fill={fill}
            points="157.26,235.04 98.31,57.29 115.86,57.29 115.86,21.29 23.61,21.29 23.61,57.29 42.96,57.29 112.26,265.19 
			92.01,265.19 92.01,301.19 225.21,301.19 225.21,265.19 202.71,265.19 272.01,57.29 294.96,57.29 294.96,21.29 199.56,21.29 
			199.56,57.29 216.66,57.29 		"
          />
          <path
            fill={fill}
            d="M182.01,3.74v71.1h10.35l-35.1,104.85l-35.1-104.85h10.8V3.74H6.06v71.1h25.2l57.6,173.25H74.91v70.65h167.85v-70.65
			h-16.2l2.46-14.92c-0.01-0.01,0.09-9.8,0.09-9.81c-0.14-1.35-0.24-7.4-0.09-8.84c-0.07-0.29-0.42-3.24-0.45-3.54
			c-0.11-0.01-1.58-2.9-1.58-2.92c0,0,1.88,8.58,2,6.88c0,0-0.03,4.61-0.03,4.61c0.24,0.02,0.05,4.33,0.06,2.23l-11.46,33.5h17.55
			v55.8H82.56v-55.8h14.85l-63.9-188.1h-19.8v-55.8h111.6v55.8h-11.7l43.65,126.9l44.1-126.9h-11.7v-55.8h115.2v55.8h-22.95
			l-41.63,121.68c0.06,0,0.1,0,0.12,0c0.71-0.01,1.41-0.01,2.12-0.01c0.58,0,1.15,0,1.73,0.01c0.9-0.04,1.69-0.06,1.99,0.01
			l37.91-114.04h27.9V3.74H182.01z"
          />
        </g>
      </g>
    </svg>
  )
}

SolidColorLogo.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.shape({}),
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

SolidColorLogo.defaultProps = {
  className: {},
  style: {},
  height: "50px",
  width: "50px",
  fill: "#000",
}

export default SolidColorLogo

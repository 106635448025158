import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
// import { useSiteMetadata } from '../hooks/use-site-metadata'

import Logo from '../icons/Logo'
import SimplifiedLogo from '../icons/SimplifiedLogo'

const Header = styled.header`
  background: ${props => props.theme.colors.primary};
  width: 100%;
  padding: 15px 0;
`

const LogoDiv = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;

  > * {
    margin-right: 5px;
  }
`

const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  li {
    display: inline-block;
    margin-left: 1em;
    &:first-of-type {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }
  }

  a {
    white-space: nowrap;
    text-decoration: none;
    color: #e4e6eb;
    font-weight: 600;
    transition: all 0.2s;
    &:hover {
      color: white;
    }
  }
`

// const activeLinkStyle = {
//   color: 'white'
// }

const Menu = () => {
  // const { menuLinks } = useSiteMetadata()
  return (
    <Header>
      <Nav>
        <ul>
          <li>
            <Link to="/">
              <LogoDiv>
                <Logo width="50px" height="50px" fill="white" />
                <SimplifiedLogo width="250px" height="33px" fill="white" />
              </LogoDiv>
            </Link>
          </li>
          {/* {menuLinks.map(link => (
            <li key={link.name}>
              <Link to={link.slug} activeStyle={activeLinkStyle}>
                {link.name}
              </Link>
            </li>
          ))} */}
          <li>
            <a href="https://varsityhype.com/mission">MISSION</a>
          </li>
          <li>
            <a href="https://varsityhype.com/teams-info">TEAM</a>
          </li>
          <li>
            <a href="https://varsityhype.com/leagues-info">LEAGUE</a>
          </li>
          <li>
            <a href="https://varsityhype.com/faq">HELP</a>
          </li>
          <li>
            <a href="https://varsityhype.com/login">LOGIN</a>
          </li>
          <li>
            <a href="https://varsityhype.com/signup">SIGN UP</a>
          </li>
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu

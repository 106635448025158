import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`

export const Label = styled.span`
  display: inline-block;
  position: absolute;
  left: 10px;
  transition: all 150ms ease-in;
  color: ${props => props.theme.colors.textFootNote};
  padding: 1px;
  order: +1;
  font-weight: 300;
  pointer-events: none;
`

const StyledInput = styled.input`
  border: 1px solid;
  border-color: ${props =>
    props.error ? 'red' : props.theme.colors.textFootNote};
  border-radius: 5px;
  color: ${props => props.theme.colors.textMain};
  padding: 16px;
  box-sizing: border-box;
  font-weight: 300;
  justify-content: flex-start;
  width: 100%;
  background-color: #fff;

  &:not([value='']) ~ ${Label} {
    display: ${props => (props.onClickHidePlaceholder ? 'none' : 'inline')};
    transform: translateY(-31px);
    font-size: 0.9em;
    color: ${props => (props.error ? 'red' : props.theme.colors.textFootNote)};
  }
  &:focus {
    border-color: ${props => props.theme.colors.tertiaryColor};
    outline: none;
  }
  &:focus ~ ${Label} {
    transform: translateY(-31px);
    font-size: 0.9em;
    color: ${props => props.theme.colors.tertiaryColor};
  }
  &:invalid:focus {
    border-color: red;
    &:focus ~ ${Label} {
      color: red;
    }
  }

  /* When you pass the onClickHidePlaceholder prop the text disappears when focused */
  &:focus ~ ${Label} {
    display: ${props => (props.onClickHidePlaceholder ? 'none' : 'inline')};
  }
`

const Input = ({
  placeholder,
  onChange,
  value,
  id,
  name,
  className,
  inputStyles,
  disabled,
  type,
  error,
  onKeyDown,
  autoComplete,
  autoFocus,
  ref,
  style,
  ...rest
}) => {
  return (
    <Container className={className} style={style}>
      <StyledInput
        id={id}
        ref={ref}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={error}
        onKeyDown={onKeyDown}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        style={inputStyles}
        {...rest}
      />
      {placeholder.length > 0 && <Label>{placeholder}</Label>}
    </Container>
  )
}

Input.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inputStyles: PropTypes.shape({}),
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onKeyDown: PropTypes.func,
  autoComplete: PropTypes.string,
  error: PropTypes.bool,
  autoFocus: PropTypes.bool,
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  style: PropTypes.shape({})
}

Input.defaultProps = {
  className: {},
  inputStyles: {},
  onKeyDown: null,
  placeholder: '',
  disabled: false,
  type: 'text',
  autoComplete: undefined,
  id: undefined,
  error: false,
  autoFocus: false,
  ref: undefined,
  style: {}
}

export default Input

import React from "react"
import PropTypes from "prop-types"

function SimplifiedLogo({ className, height, width, fill, style }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 260 37"
      xmlSpace="preserve"
      className={className}
      style={style}
      height={height}
      width={width}
      fill={fill}
    >
      <g>
        <path
          d="M10.03,5.91l2.88,15.12h0.36l1.98-9.36l-2.05-0.36v-5.4h11.59v5.4l-2.05,0.36l-4.86,19.44H8.3L3.44,11.67l-2.05-0.36v-5.4
   H10.03z"
        />
        <path
          d="M40.67,5.91l5.9,19.44l2.05,0.36v5.4h-11.3v-5.4l2.05-0.36l-0.32-1.26h-5.76l-0.32,1.26l2.05,0.36v5.4h-11.3v-5.4
   l2.05-0.36l4.1-13.68l-2.05-0.36v-5.4H40.67z M35.98,13.69l-1.3,5h2.95l-1.3-5H35.98z"
        />
        <path
          d="M50.06,11.31v-5.4h13.93c2.33,0,4.11,0.61,5.35,1.84c1.24,1.22,1.85,3.01,1.85,5.36v2.88c0,0.67-0.08,1.3-0.25,1.87
   s-0.37,1.03-0.59,1.35c-0.23,0.32-0.46,0.61-0.7,0.85c-0.24,0.24-0.44,0.4-0.61,0.47l-0.25,0.14v0.36l1.62,4.32l2.05,0.36v5.4
   h-8.21l-2.48-7.92h-2.09v7.92h-9.61v-5.4l2.05-0.36V11.67L50.06,11.31z M63.63,15.63v-2.16c0-0.53-0.1-0.9-0.31-1.12
   c-0.2-0.22-0.58-0.32-1.13-0.32h-2.52v5.04h2.52c0.55,0,0.93-0.11,1.13-0.32C63.53,16.53,63.63,16.16,63.63,15.63z"
        />
        <path
          d="M75.73,22.47h5.58l0.54,2.16h3.56c0.53,0,0.9-0.11,1.12-0.32c0.22-0.22,0.32-0.59,0.32-1.12v-0.36
   c0-0.53-0.11-0.9-0.32-1.12c-0.22-0.21-0.59-0.32-1.12-0.32h-2.88c-2.35,0-4.14-0.61-5.36-1.84c-1.22-1.22-1.84-3.01-1.84-5.36
   v-1.08c0-2.35,0.61-4.14,1.84-5.36c1.22-1.22,3.01-1.84,5.36-1.84h10.62v8.64h-5.58l-0.54-2.16h-2.7c-0.53,0-0.9,0.11-1.12,0.32
   c-0.22,0.22-0.32,0.59-0.32,1.12v0.36c0,0.53,0.11,0.9,0.32,1.12c0.22,0.22,0.59,0.32,1.12,0.32h2.88c2.35,0,4.14,0.61,5.36,1.84
   s1.84,3.01,1.84,5.36v1.08c0,2.35-0.61,4.14-1.84,5.36s-3.01,1.84-5.36,1.84H75.73V22.47z"
        />
        <path d="M97.29,11.31v-5.4h11.66v5.4l-2.05,0.36v13.68l2.05,0.36v5.4H97.29v-5.4l2.05-0.36V11.67L97.29,11.31z" />
        <path
          d="M111.48,5.91h21.67v9h-5.08l-0.54-2.52h-1.44v12.96l2.05,0.36v5.4h-11.66v-5.4l2.05-0.36V12.39h-1.44l-0.54,2.52h-5.08
   V5.91z"
        />
        <path
          d="M146,13.83l3.06-7.92h7.63v5.4l-2.02,0.36l-5.08,10.8v2.88l2.05,0.36v5.4h-11.66v-5.4l2.05-0.36v-2.88l-5.08-10.8
   l-2.02-0.36v-5.4h7.63l3.06,7.92H146z"
        />
        <path
          d="M174.94,5.91v9.36h4.79v-3.6l-2.05-0.36v-5.4h11.66v5.4l-2.05,0.36v13.68l2.05,0.36v5.4h-9.61v-9.36h-4.79v3.6l2.05,0.36
   v5.4h-11.66v-5.4l2.05-0.36V11.67l-2.05-0.36v-5.4H174.94z"
        />
        <path
          d="M202.55,13.83l3.06-7.92h7.63v5.4l-2.02,0.36l-5.08,10.8v2.88l2.05,0.36v5.4h-11.66v-5.4l2.05-0.36v-2.88l-5.08-10.8
   l-2.02-0.36v-5.4h7.63l3.06,7.92H202.55z"
        />
        <path
          d="M228.94,5.91c2.35,0,4.14,0.61,5.36,1.84c1.22,1.22,1.84,3.01,1.84,5.36v3.6c0,2.35-0.61,4.14-1.84,5.36
   c-1.22,1.22-3.01,1.84-5.36,1.84h-3.92v1.44l2.05,0.36v5.4h-11.66v-5.4l2.05-0.36V11.67l-2.05-0.36v-5.4H228.94z M228.58,16.35
   v-2.88c0-0.53-0.11-0.9-0.32-1.12c-0.22-0.22-0.59-0.32-1.12-0.32h-2.12v5.76h2.12c0.53,0,0.9-0.11,1.12-0.32
   S228.58,16.88,228.58,16.35z"
        />
        <path
          d="M239.02,31.11v-5.4l2.05-0.36V11.67l-2.05-0.36v-5.4h19.69v9h-5.58l-0.54-2.52h-3.96v3.24h4.79v5.76h-4.79v3.24h4.36
   l0.54-2.52h5.58v9H239.02z"
        />
      </g>
    </svg>
  )
}

SimplifiedLogo.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.shape({}),
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

SimplifiedLogo.defaultProps = {
  className: {},
  style: {},
  height: "40px",
  width: "270px",
  fill: "#555555",
}

export default SimplifiedLogo
